@import "../../styles/responsive.scss";

.messagenewform {
  text-align: left;
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.article-form form {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.article-form div {
  margin-bottom: 15px;
}

.article-form label {
  display: block;
  margin-bottom: 5px;
}

.article-form input[type="text"],
.article-form .ql-container {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.article-form .ql-container {
  height: 300px; /* Set a fixed height for the editor container */
  overflow-y: auto; /* Make it scrollable if content overflows */
}

.article-form .ql-editor {
  height: calc(100% - 42px); /* Adjust height to fit the container minus the toolbar height */
  overflow-y: auto; /* Ensure the editor content itself is scrollable */
}

.article-form button {
  padding: 10px 20px;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.article-form button:hover {
  background-color: #21a1f1;
}

@media (max-width: 600px) {
  .article-form {
    padding: 10px;
  }

  .article-form button {
    padding: 10px;
    width: 100%;
  }

  .ql-toolbar {
    overflow-x: auto;
  }
}
