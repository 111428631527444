body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.note-box {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100vh; /* Set the note-box to take the full height of the viewport */
  box-sizing: border-box;
  overflow: hidden; /* Prevent scrolling for the outer container */
}

.note-box h1 {
  margin-bottom: 20px;
}

.note-box h2 {
  margin: 10px 0;
}

.note-box input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.editor-container {
  display: flex;
  flex-direction: column;
  height: 60vh; /* Set the height to 60% of the viewport height */
  overflow: hidden; /* Prevent the container itself from scrolling */
}

.ql-container {
  height: 100%; /* Ensure the editor container takes up the full height */
  display: flex;
  flex-direction: column;
}

.ql-toolbar {
  flex: none; /* Ensure the toolbar doesn't grow */
}

.ql-editor {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling for the editor content */
  height: auto; /* Auto height for content */
}

.editor-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.note-box button {
  margin: 10px 5px 0 0;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.note-box .error {
  color: red;
}

.delete-button {
  margin: 10px 0;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
}

.delete-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}