@import "../../styles/responsive.scss";

.services {
  display: flex;

  @include xl{
    flex-direction: column;
    gap: 50px;
  }

  .view {
    flex: 1;

    .info {
      .topInfo {
        display: flex;
        align-items: center;
        gap: 20px;

        img {
          width: 100px;
          height: 100px;
          border-radius: 20px;
          object-fit: cover;
        }

        h1 {
          font-weight: 500;
        }
      }
      .details {
        font-size: 18px;

        .item {
          margin: 30px 0px;

          .itemTitle {
            font-weight: 600;
            margin-right: 10px;
            text-transform: capitalize;
          }
        }
      }
    }

    hr {
      width: 90%;
      height: 0;
      border: 0.5px solid gray;
      margin: 20px 0px;

      @include md{
        display: none;
      }
    }

    .chart {
      margin-top: 50px;
      width: 80%;
      height: 400px;

      @include md{
        display: none;
      }
    }
  }
  .activities {
    flex: 1;

    h2 {
      margin-bottom: 20px;
    }

    ul {
      li {
        list-style-type: none;
        position: relative;
        width: 1px;
        padding-top: 50px;
        background-color: #f45b69;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #f45b69;
          transform: translateX(-50%);
        }

        div {
          min-width: 480px;
          padding: 15px;
          background-color: #f45b6810;

          @include md{
            min-width: 250px;
          }

          p{
            margin-bottom: 5px;
          }

          time{
            font-size: 12px;
          }
        }
      }
    }
  }
}
