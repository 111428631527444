.chat-container {
    display: flex;
    flex-direction: column;
    height: 80vh;
    max-width: 100%;
    margin: 50px auto;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .chat-box {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column; /* Normal order of messages */
    justify-content: flex-end; /* Align messages to the bottom */
  }
  
  .message {
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 5px;
    max-width: 80%;
    word-wrap: break-word;
    text-align: left; /* Ensures text is left-aligned */
  }
  
  .chat-user {
    background-color: green;
    color: #333;
    align-self: flex-end;
  }
  
  .chat-agent {
    background-color: #007bff;
    color: #fff;
    align-self: flex-start;
  }
  
  .input-box {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd; /* Add a border to separate the input box */
  }
  
  .input-box input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .input-box button {
    padding: 8px 16px;
    background-color: red;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .input-box button:hover {
    background-color: darken(red, 10%);
  }

  /* Spinner Styles */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    display: inline-block;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }


/* Typing Indicator Styles */
.typing-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px; /* Adjust height as needed */
  }
  
  .typing-indicator span {
    width: 8px; /* Increased size */
    height: 8px; /* Increased size */
    margin: 0 4px; /* Increased margin */
    background-color: #ffffff; /* White color */
    border-radius: 50%;
    opacity: 0;
    animation: typing 1.5s infinite; /* Slower animation for better visibility */
  }
  
  .typing-indicator span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .typing-indicator span:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .typing-indicator span:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes typing {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }