.ideas{

    .info{
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;
  
      button{
        padding: 5px;
        cursor: pointer;
      }
    }
  }