body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.calendar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  //background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.header button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.header div {
  font-size: 16px;
  font-weight: bold;
}

.header button:last-of-type {
  background-color: #61dafb;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.header button:last-of-type:hover {
  background-color: #21a1f1;
}

.filters {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.filters label {
  margin: 0 10px;
  font-size: 14px;
}

.days-of-week {
  display: flex;
 // background-color: #e0e0e0;
  border-bottom: 1px solid #ddd;
}

.day-of-week {
  flex: 1;
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
}

.days {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.day, .empty-day {
  flex: 1 0 14.28%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  position: relative;
}

.day {
 // cursor: pointer;
  display: flex;
  flex-direction: column;
}

.date:hover {
  background-color: #f0f0f0;
}

.date {
  padding: 5px;
  font-size: 14px;
  text-align: right;
  border-bottom: 1px solid #ddd;
  //background-color: #f9f9f9;
}

.event {
  margin: 2px 0;
  padding: 2px;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.event.calendar-1 {
  background-color: #61dafb;
  color: white;
}

.event.calendar-2 {
  background-color: #ff7043;
  color: white;
}

.day-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.date-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .header button:last-of-type {
    font-size: 12px;
  }
}
